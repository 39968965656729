//import { setMinimumOrder } from "./actions/cartActions";
const initMainData = () => {
  Promise.all([
    import(/* webpackChunkName: "React" */ "react"),
    import(/* webpackChunkName: "Store" */ "./store/store"),
   //import(/* webpackChunkName: "UserActions" */ "./actions/userActions"),
    //import(/* webpackChunkName: "ProductActions" */ "./actions/productActions"),
  ]).then(([{ default: React }, Store]) => {
    const { store } = Store;
    //const { setMemberType, setLoggedIn, requestInfo } = UserActions;
    //const { setCurrentWarehouse } = ProductActions; // getting current warehouse
    //const loggedIn = document.getElementById("isLoggedIn");

    //store.dispatch(setMemberType(window.memberType || "retail-customer"));
    // store.dispatch(
    //   setLoggedIn(window.loggedIn || (loggedIn && loggedIn.value) || false)
    // );
    //store.dispatch(setCurrentWarehouse(window.warehouse || 2));
    //store.dispatch(requestInfo());
  });
};

const initDashboard = () => {
  const el = document.getElementById("dasboard-widget");
  if (el) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "LayoutBuilder" */ "./containers/LayoutBuilderContainer"
      ),
      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: LayoutBuilder },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        ReactDOM.render(
          <LocalizationProvider resources={getResources()}>
            <Provider store={store}>
              <LayoutBuilder />
            </Provider>
          </LocalizationProvider>,
          el
        );
      }
    );
  }
};

const initUser = () => {
  const el = document.getElementById("user-account");
  if (el) {
    Promise.all([
      import(/* webpackChunkName: "React" */ "react"),
      import(/* webpackChunkName: "ReactDOM" */ "react-dom"),
      import(/* webpackChunkName: "ReactRedux" */ "react-redux"),
      import(/* webpackChunkName: "Store" */ "./store/store"),
      import(
        /* webpackChunkName: "LayoutBuilder" */ "./containers/UserContainer"
      ),
      import(/* webpackChunkName: "Reactl10n" */ "react-l10n"),
      import(/* webpackChunkName: "MainLanguage" */ "./lang/main"),
    ]).then(
      ([
        { default: React },
        { default: ReactDOM },
        ReactRedux,
        Store,
        { default: User },
        { LocalizationProvider },
        { getResources },
      ]) => {
        const { Provider } = ReactRedux;
        const { store } = Store;

        ReactDOM.render(
          <LocalizationProvider resources={getResources()}>
            <Provider store={store}>
              <User />
            </Provider>
          </LocalizationProvider>,
          el
        );
      }
    );
  }
};

export const init = () => {
  // Main
  // ---------------------
  initMainData();
  initDashboard();
  initUser();
};
